import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Compass, Search, PlusCircle, Menu, Calendar } from 'lucide-react';
import AppContext from '../context/AppContext';

function Footer() {
  const location = useLocation();
  const { setIsSidebarOpen } = useContext(AppContext);

  const isActive = (path) => {
    return location.pathname === path ? 'text-[#5CD2C1]' : 'text-gray-400';
  };

  return (
    <footer className="bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 fixed bottom-0 left-0 right-0 z-10">
      <nav className="flex justify-between items-center max-w-md mx-auto">
        <Link 
          to="/my-meetz" 
          className={`flex flex-col items-center w-1/5 py-2 ${isActive('/my-meetz')}`}
        >
          <Calendar className="w-7 h-7" strokeWidth={1.5} />
          <span className="text-[10px] mt-1 font-medium">my meetz</span>
        </Link>
        <Link 
          to="/discover" 
          className={`flex flex-col items-center w-1/5 py-2 ${isActive('/discover')}`}
        >
          <Compass className="w-7 h-7" strokeWidth={1.5} />
          <span className="text-[10px] mt-1 font-medium">discover</span>
        </Link>
        <Link 
          to="/create" 
          className={`flex flex-col items-center w-1/5 py-2 ${isActive('/create')}`}
        >
          <PlusCircle className="w-9 h-9" strokeWidth={1.5} />
          <span className="text-[10px] mt-1 font-medium">create</span>
        </Link>
        <Link 
          to="/search" 
          className={`flex flex-col items-center w-1/5 py-2 ${isActive('/search')}`}
        >
          <Search className="w-7 h-7" strokeWidth={1.5} />
          <span className="text-[10px] mt-1 font-medium">search</span>
        </Link>
        <button 
          className="flex flex-col items-center w-1/5 py-2 text-gray-400"
          onClick={() => setIsSidebarOpen(true)}
        >
          <Menu className="w-7 h-7" strokeWidth={1.5} />
          <span className="text-[10px] mt-1 font-medium">more</span>
        </button>
      </nav>
    </footer>
  );
}

export default Footer;
import React, { useState, useEffect } from 'react';
import { Users, Trophy, Star, Gift, Globe, Calendar } from 'lucide-react';
import { fetchProfile } from '../services/api';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { useParams } from 'react-router-dom'; // Import useParams from react-router-dom

function Profile() {
  const { user: currentUser } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const { id } = useParams(); // Add this line to get the user ID from the URL

  useEffect(() => {
    const loadUserProfile = async () => {
      try {
        setLoading(true);
        const userId = id || currentUser.id; // Use the URL param if available, otherwise use current user's ID
        const userData = await fetchProfile(userId);
        setProfileData(userData);
        setLoading(false);
      } catch (err) {
        console.error('Error loading user profile:', err);
        setError('Failed to load user profile');
        setLoading(false);
      }
    };

    loadUserProfile();
  }, [id, currentUser]); // Add id to the dependency array

  const achievements = [
    { title: "Social Butterfly", description: "Connected your social media accounts", icon: <Users className="h-6 w-6" />, progress: 100 },
    { title: "Trendsetter", description: "Created a meet that went viral", icon: <Trophy className="h-6 w-6" />, progress: 75 },
    { title: "Influencer", description: "Reached 1000 followers on Meetz", icon: <Star className="h-6 w-6" />, progress: 50 },
    { title: "Event Maestro", description: "Successfully organized 10 meets", icon: <Gift className="h-6 w-6" />, progress: 30 },
    { title: "Global Citizen", description: "Attended meets in 5 different countries", icon: <Globe className="h-6 w-6" />, progress: 20 },
  ];

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!profileData) return <div>User not found</div>;

  return (
    <div className="p-6 bg-[#e6f3f0] dark:bg-gray-900">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 mb-6">
        <div className="flex items-center mb-4">
          <img 
            src={profileData.profilePicture || 'default-profile-picture.jpg'} 
            alt={profileData.displayName || 'User'} 
            className="w-20 h-20 rounded-full mr-4" 
          />
          <div>
            <h2 className="text-2xl font-bold text-[#2A8C7F] dark:text-[#5CD2C1]">
              {profileData.displayName || 'User'}
            </h2>
            <p className="text-gray-600 dark:text-gray-300">
              {profileData.email || 'Email not available'}
            </p>
          </div>
        </div>
        <div className="flex items-center text-gray-600 dark:text-gray-300">
          <Calendar className="w-4 h-4 mr-2" />
          Joined {profileData.createdAt ? new Date(profileData.createdAt).toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          }) : 'Unknown date'}
        </div>
      </div>
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
        <h3 className="text-xl font-bold text-[#2A8C7F] dark:text-[#5CD2C1] mb-4">Achievements</h3>
        <div className="space-y-4">
          {achievements.map((achievement, index) => (
            <div key={index} className="flex items-center">
              <div className="mr-4">{achievement.icon}</div>
              <div className="flex-grow">
                <h4 className="font-semibold text-gray-800 dark:text-gray-200">{achievement.title}</h4>
                <p className="text-sm text-gray-600 dark:text-gray-400">{achievement.description}</p>
                <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                  <div 
                    className="bg-[#5CD2C1] h-2.5 rounded-full" 
                    style={{ width: `${achievement.progress}%` }}
                  ></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Profile;


import React, { useState } from 'react';
import { ChevronDown, Wallet, CreditCard, Bitcoin, Building } from 'lucide-react';

function Donate() {
  const [expanded, setExpanded] = useState(null);

  const toggleExpand = (method) => {
    setExpanded(expanded === method ? null : method);
  };

  const PaymentMethod = ({ method, icon, children, expandable = true }) => (
    <div className="border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden mb-3">
      <button
        onClick={() => expandable && toggleExpand(method)}
        className="w-full flex items-center justify-between p-4 bg-white dark:bg-gray-800 text-[#2A8C7F] dark:text-[#5CD2C1]"
      >
        <div className="flex items-center">
          {icon}
          <span className="ml-2">{method}</span>
        </div>
        {expandable && <ChevronDown className={`h-5 w-5 transform ${expanded === method ? 'rotate-180' : ''}`} />}
      </button>
      {expandable && expanded === method && <div className="p-4 bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300">{children}</div>}
    </div>
  );

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold text-[#2A8C7F] dark:text-[#5CD2C1] mb-4">keep meetz alive—drop some support ❤️</h2>
      <p className="text-gray-600 dark:text-gray-400 mb-6">
        hey squad! running meetz solo isn't exactly cheap... there's a lot of behind-the-scenes stuff with server costs 💻, subscriptions 📉, and keeping everything running smoothly (wish me luck 🤞). if you're loving what i'm building and wanna help keep it going, any donation would be awesome 🙌
      </p>
      <PaymentMethod method="GCash" icon={<Wallet className="h-5 w-5" />}>
        <p>GCash payment details here</p>
      </PaymentMethod>
      <PaymentMethod method="PayMaya" icon={<CreditCard className="h-5 w-5" />}>
        <p>PayMaya payment details here</p>
      </PaymentMethod>
      <PaymentMethod method="Crypto Network" icon={<Bitcoin className="h-5 w-5" />}>
        <p>Crypto payment details here</p>
      </PaymentMethod>
      <PaymentMethod method="Bank Transfer" icon={<Building className="h-5 w-5" />}>
        <p>Bank transfer details here</p>
      </PaymentMethod>
      <PaymentMethod method="Credit Card (coming soon)" icon={<CreditCard className="h-5 w-5" />} expandable={false} />
    </div>
  );
}

export default Donate;
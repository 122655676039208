import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import AppContext from './context/AppContext';
import Header from './components/Header';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import Login from './pages/Login';
import Discover from './pages/Discover';
import Search from './pages/Search';
import Profile from './pages/Profile';
import CreateMeet from './pages/CreateMeet';
import MeetDetails from './pages/MeetDetails';
import Donate from './pages/Donate';
import UserProfile from './pages/UserProfile';
import PrivacyPolicy from './pages/PrivacyPolicy';
import DataDeletionInstructions from './components/DataDeletionInstructions';

import './index.css';

import { signInWithGoogle, logOut, createUserWithGoogle } from './firebase/auth';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase/config';

import MyMeetz from './pages/MyMeetz';

import { UserProvider } from './context/UserContext';

function MainContent({ children }) {
  const location = useLocation();
  return (
    <main className={`main-content bg-white dark:bg-gray-900 text-gray-900 dark:text-white ${location.pathname !== '/login' ? 'pt-16' : ''}`}>
      {children}
    </main>
  );
}

function App() {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser && savedUser !== 'undefined' ? JSON.parse(savedUser) : null;
  });
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode === 'true';
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  useEffect(() => {
    if (user !== undefined) {
      localStorage.setItem('user', JSON.stringify(user));
    }
  }, [user]);

  const toggleDarkMode = () => setDarkMode(!darkMode);

  const handleSignInWithGoogle = async () => {
    try {
      const user = await signInWithGoogle();
      setUser(user);
      return user;
    } catch (error) {
      console.error("Error signing in with Google", error);
      throw error;
    }
  };

  const handleLogout = async () => {
    try {
      await logOut();
      setUser(null);
    } catch (error) {
      console.error("Error logging out", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        setUser({
          id: firebaseUser.uid,
          displayName: firebaseUser.displayName,
          email: firebaseUser.email,
          photoURL: firebaseUser.photoURL,
        });
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserProvider>
      <AppContext.Provider value={{ 
        darkMode, 
        toggleDarkMode, 
        isSidebarOpen, 
        setIsSidebarOpen,
        handleSignInWithGoogle,
        handleLogout,
        createUserWithGoogle
      }}>
        <Router>
          <div className={`app ${darkMode ? 'dark' : ''}`}>
            <Header />
            <MainContent>
              <Switch>
                <Route exact path="/" render={() => <Redirect to="/login" />} />
                <Route exact path="/login" component={Login} />
                <Route path="/discover" render={() => user ? <Discover /> : <Redirect to="/login" />} />
                <Route path="/search" render={() => user ? <Search /> : <Redirect to="/login" />} />
                <Route path="/profile" component={Profile} />
                <Route path="/profiles/:id" component={UserProfile} />
                <Route path="/create" render={() => user ? <CreateMeet /> : <Redirect to="/login" />} />
                <Route path="/meet/:id" render={() => user ? <MeetDetails /> : <Redirect to="/login" />} />
                <Route path="/donate" render={() => user ? <Donate /> : <Redirect to="/login" />} />
                <Route path="/data-deletion" component={DataDeletionInstructions} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/my-meetz" component={MyMeetz} />
              </Switch>
            </MainContent>
            {user && <Footer />}
            {user && <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />}
          </div>
        </Router>
      </AppContext.Provider>
    </UserProvider>
  );
}

export default App;
import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../context/AppContext';

function Login() {
  const history = useHistory();
  const { user, loading, error, darkMode, handleSignInWithGoogle, createUserWithGoogle } = useContext(AppContext);
  const [loginError, setLoginError] = useState(null);

  const handleGoogleLogin = async () => {
    try {
      setLoginError(null);
      await handleSignInWithGoogle();
      history.push('/discover');
    } catch (error) {
      setLoginError("Failed to sign in with Google. Please try again.");
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      setLoginError(null);
      await createUserWithGoogle();
      history.push('/discover');
    } catch (error) {
      setLoginError("Failed to create account with Google. Please try again.");
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (user) {
    history.push('/discover');
    return null;
  }

  return (
    <div className={`min-h-screen flex flex-col ${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      <main className="flex-grow flex flex-col items-center justify-center p-4">
        <div className="text-6xl font-bold mb-12 flex items-center">
          meetz <span className="ml-2">🤝</span>
        </div>
        
        <div className="w-full max-w-md space-y-4">
          <button 
            className="w-full p-3 bg-[#5CD2C1] hover:bg-[#4CB3A3] text-white rounded-lg flex items-center justify-center"
          >
            what is meetz? 🤔
          </button>

          <button 
            className="w-full p-3 bg-[#4285F4] hover:bg-[#357AE8] text-white rounded-lg flex items-center justify-center"
            onClick={handleGoogleLogin}
          >
            <svg className="mr-2" width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
              {/* Google icon SVG path */}
            </svg>
            Login with Google
          </button>

          <button 
            className="w-full p-3 bg-[#34A853] hover:bg-[#2E7D32] text-white rounded-lg flex items-center justify-center"
            onClick={handleGoogleSignUp}
          >
            <svg className="mr-2" width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
              {/* Google icon SVG path */}
            </svg>
            Sign Up with Google
          </button>
        </div>
        
        {loginError && <p className="text-red-500 mt-2">{loginError}</p>}
      </main>
    </div>
  );
}

export default Login;

import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { MapPin, Calendar, Clock } from 'lucide-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { createMeetz } from '../services/api';
import { UserContext } from '../context/UserContext'; // Assume you have a UserContext

function CreateMeet() {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [meetData, setMeetData] = useState({
    title: '',
    tags: [],
    location: '',
    dateTime: new Date(),
    description: '',
  });
  const [tagInput, setTagInput] = useState('');
  const [showLocationInput, setShowLocationInput] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);

  const suggestedTags = ['coffee', 'tech', 'yoga', 'art', 'music', 'food', 'sports', 'books'];

  const handleChange = (e) => {
    setMeetData({ ...meetData, [e.target.name]: e.target.value });
  };

  const handleTagInput = (e) => {
    if (e.key === 'Enter' || e.type === 'blur') {
      e.preventDefault();
      if (tagInput.trim() !== '' && !meetData.tags.includes(tagInput.trim().toLowerCase())) {
        setMeetData({
          ...meetData,
          tags: [...meetData.tags, tagInput.trim().toLowerCase()],
        });
        setTagInput('');
      }
    }
  };

  const addTag = (tag) => {
    if (!meetData.tags.includes(tag)) {
      setMeetData({
        ...meetData,
        tags: [...meetData.tags, tag],
      });
    }
  };

  const removeTag = (tagToRemove) => {
    setMeetData({
      ...meetData,
      tags: meetData.tags.filter((tag) => tag !== tagToRemove),
    });
  };

  const addSuggestedDeetz = () => {
    if (!meetData.tags.includes('deetz')) {
      setMeetData({
        ...meetData,
        tags: [...meetData.tags, 'deetz'],
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      console.error('User is not logged in');
      return;
    }
    const newMeet = {
      ...meetData,
      hostId: user.id,
      attendees: [user.id],
      pendingAttendees: []
    };

    try {
      await createMeetz(newMeet);
      history.push('/my-meetz');
    } catch (error) {
      console.error('Error creating meet:', error);
    }
  };

  const handleDateChange = (date) => {
    setMeetData({ ...meetData, dateTime: date });
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 w-full max-w-md mx-auto">
        <h1 className="text-2xl font-bold text-center text-[#2A8C7F] dark:text-[#5CD2C1] mb-4">create a meetz</h1>
        <form onSubmit={handleSubmit} className="space-y-3">
          <input
            type="text"
            name="title"
            value={meetData.title}
            onChange={handleChange}
            placeholder="what's your meetz about?"
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#5CD2C1] bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
          
          <div>
            <input
              type="text"
              value={tagInput}
              onChange={(e) => setTagInput(e.target.value)}
              onKeyPress={handleTagInput}
              onBlur={handleTagInput}
              placeholder="add meetztags (press enter)"
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#5CD2C1] bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            />
            <div className="flex flex-wrap gap-1 mt-1">
              {meetData.tags.map((tag) => (
                <span key={tag} className="bg-[#e6f3f0] text-[#2A8C7F] px-2 py-1 rounded-full text-xs flex items-center">
                  #{tag}
                  <button
                    type="button"
                    onClick={() => removeTag(tag)}
                    className="ml-1 text-[#2A8C7F] hover:text-red-500 focus:outline-none"
                  >
                    ×
                  </button>
                </span>
              ))}
            </div>
          </div>

          <div>
            <p className="text-xs text-gray-600 dark:text-gray-400 mb-1">suggested meetztags:</p>
            <div className="flex flex-wrap gap-1">
              {suggestedTags.map((tag) => (
                <button
                  key={tag}
                  type="button"
                  onClick={() => addTag(tag)}
                  className="bg-[#5CD2C1] text-white px-2 py-1 rounded-full text-xs hover:bg-[#4CB3A3]"
                >
                  #{tag}
                </button>
              ))}
            </div>
          </div>

          <div className="relative">
            <MapPin className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#5CD2C1]" size={16} />
            <input
              type="text"
              name="location"
              value={meetData.location}
              onChange={handleChange}
              placeholder="add location"
              className="w-full pl-8 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#5CD2C1] bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            />
          </div>

          <div className="space-y-2">
            <div className="relative">
              <Calendar className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#5CD2C1]" size={16} />
              <DatePicker
                selected={meetData.dateTime ? new Date(meetData.dateTime) : null}
                onChange={(date) => setMeetData({ ...meetData, dateTime: date })}
                dateFormat="MMMM d, yyyy"
                className="w-full pl-8 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#5CD2C1] bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                placeholderText="Select date"
              />
            </div>
            <div className="relative">
              <Clock className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#5CD2C1]" size={16} />
              <DatePicker
                selected={meetData.dateTime ? new Date(meetData.dateTime) : null}
                onChange={(date) => setMeetData({ ...meetData, dateTime: date })}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className="w-full pl-8 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#5CD2C1] bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                placeholderText="Select time"
              />
            </div>
          </div>

          <textarea
            name="description"
            value={meetData.description}
            onChange={handleChange}
            placeholder="deetz (optional)"
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#5CD2C1] bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            rows="4"
          ></textarea>

          <button
            type="submit"
            className="w-full bg-[#5CD2C1] text-white py-2 px-4 rounded-lg hover:bg-[#4CB3A3] transition duration-300"
          >
            create meetz
          </button>
        </form>
      </div>
    </div>
  );
}

export default CreateMeet;
import axios from 'axios';

//const API_BASE_URL = 'http://localhost:8080/api.meetz.site/';
const API_BASE_URL = 'https://api.meetz.site';

export const fetchMeetz = async () => {
  const response = await axios.get(`${API_BASE_URL}/meetz`);
  return response.data;
};

export const createMeetz = async (meetData) => {
  const response = await axios.post(`${API_BASE_URL}/meetz`, meetData);
  return response.data;
};

export const joinMeetz = async (meetId, userId) => {
  const response = await axios.post(`${API_BASE_URL}/meetz/${meetId}/join`, { userId });
  return response.data;
};

export const flakeMeetz = async (meetId, userId) => {
  const response = await axios.post(`${API_BASE_URL}/meetz/${meetId}/flake`, { userId });
  return response.data;
};

export const updateMeetz = async (meetId, meetData) => {
  const response = await axios.put(`${API_BASE_URL}/meetz/${meetId}`, meetData);
  return response.data;
};

export const acceptAttendee = async (meetId, attendeeId) => {
  const response = await axios.post(`${API_BASE_URL}/meetz/${meetId}/accept-attendee`, { attendeeId });
  return response.data;
};

export const declineAttendee = async (meetId, attendeeId) => {
  const response = await axios.post(`${API_BASE_URL}/meetz/${meetId}/decline-attendee`, { attendeeId });
  return response.data;
};

export const fetchProfile = async (userId) => {
  const response = await axios.get(`${API_BASE_URL}/profiles/${userId}`);
  return response.data;
};

export const updateProfile = async (userId, profileData) => {
  const response = await axios.put(`${API_BASE_URL}/profiles/${userId}`, profileData);
  return response.data;
};

export const fetchHostedMeetz = async (userId) => {
  const response = await axios.get(`${API_BASE_URL}/meetz/hosted/${userId}`);
  return response.data;
};

export const fetchJoinedMeetz = async (userId) => {
  const response = await axios.get(`${API_BASE_URL}/meetz/joined/${userId}`);
  return response.data;
};

export const fetchDiscoverMeetz = async () => {
  const response = await axios.get(`${API_BASE_URL}/meetz/discover`);
  return response.data;
};

// Add more API calls as needed
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { X, User, Calendar, Search, Home, LogOut } from 'lucide-react';

function Sidebar({ isOpen, onClose }) {
  const { user, logout } = useContext(UserContext);
  const history = useHistory();

  if (!isOpen) return null;

  const handleLogout = async () => {
    await logout();
    onClose();
    history.push('/login');
  };

  return (
    <div className="fixed inset-y-0 right-0 w-64 bg-white shadow-lg z-50 transform transition-transform duration-300 ease-in-out">
      <button onClick={onClose} className="absolute top-4 right-4">
        <X size={24} />
      </button>
      <div className="p-4">
        {user ? (
          <>
            <img src={user.profilePicture || '/default-avatar.png'} alt={user.name} className="w-16 h-16 rounded-full mb-4" />
            <h2 className="text-xl font-bold mb-4">{user.name}</h2>
            <nav className="space-y-2">
              <Link to="/profile" className="block p-2 hover:bg-gray-100 rounded" onClick={onClose}>
                <User className="inline-block mr-2" size={20} /> Profile
              </Link>
              <Link to="/my-meetz" className="block p-2 hover:bg-gray-100 rounded" onClick={onClose}>
                <Calendar className="inline-block mr-2" size={20} /> My Meetz
              </Link>
              <Link to="/search" className="block p-2 hover:bg-gray-100 rounded" onClick={onClose}>
                <Search className="inline-block mr-2" size={20} /> Search
              </Link>
              <Link to="/discover" className="block p-2 hover:bg-gray-100 rounded" onClick={onClose}>
                <Home className="inline-block mr-2" size={20} /> Discover
              </Link>
              <button onClick={handleLogout} className="block w-full text-left p-2 hover:bg-gray-100 rounded">
                <LogOut className="inline-block mr-2" size={20} /> Logout
              </button>
            </nav>
          </>
        ) : (
          <p>Please log in to view your profile.</p>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
import React, { useState, useEffect } from 'react';
import MeetCard from '../components/MeetCard';
import { Compass } from 'lucide-react';
import Modal from '../components/Modal';
import MeetDetails from './MeetDetails';
import { fetchDiscoverMeetz } from '../services/api';

function Discover() {
  const [meets, setMeets] = useState([]);
  const [selectedMeet, setSelectedMeet] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadMeets = async () => {
      try {
        const fetchedMeets = await fetchDiscoverMeetz();
        setMeets(fetchedMeets);
        setLoading(false);
      } catch (err) {
        setError('Failed to load meets');
        setLoading(false);
      }
    };

    loadMeets();
  }, []);

  const handleRemoveMeet = (id) => {
    setMeets(meets.filter(meet => meet.id !== id));
  };

  const handleOpenMeetDetails = (meet) => {
    setSelectedMeet(meet);
  };

  const handleCloseMeetDetails = () => {
    setSelectedMeet(null);
    // Refresh the meets list to reflect any changes
    const updatedMeets = meets.map(meet => {
      const joinedMeetz = JSON.parse(localStorage.getItem('joinedMeetz') || '[]');
      return {
        ...meet,
        isJoined: joinedMeetz.some(m => m.id === meet.id),
        attendees: Array.isArray(meet.attendees) ? meet.attendees : []
      };
    });
    setMeets(updatedMeets);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="pt-1 pb-16 px-2">
      <h1 className="text-2xl font-bold text-[#2A8C7F] mb-4 flex items-center">
        discover meetz <Compass className="ml-2" size={20} />
      </h1>
      <div className="space-y-2">
        {meets.map((meet) => (
          <MeetCard 
            key={meet.id} 
            meet={meet} 
            onRemove={handleRemoveMeet} 
            onClick={handleOpenMeetDetails} 
          />
        ))}
      </div>
      {selectedMeet && (
        <Modal isOpen={!!selectedMeet} onClose={handleCloseMeetDetails}>
          <MeetDetails meet={selectedMeet} onClose={handleCloseMeetDetails} />
        </Modal>
      )}
    </div>
  );
}

export default Discover;
import { auth } from './config';
import { 
  signInWithPopup, 
  GoogleAuthProvider, 
  signOut 
} from 'firebase/auth';

const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    // Create a user profile in your backend
    const user = {
      id: result.user.uid,
      name: result.user.displayName,
      email: result.user.email,
      profilePicture: result.user.photoURL,
      createdAt: new Date().toISOString()
    };    
    return user;
  } catch (error) {
    throw error;
  }
};

export const logOut = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error("Error signing out", error);
    throw error;
  }
};

export const deleteUserData = async () => {
  try {
    const user = auth.currentUser;
    if (user) {
      await user.delete();
      console.log("User account deleted successfully");
    } else {
      throw new Error("No user is currently signed in");
    }
  } catch (error) {
    console.error("Error deleting user account", error);
    throw error;
  }
};

export const createUserWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    
    // Create a user profile in your backend
    const user = {
      id: result.user.uid,
      name: result.user.displayName,
      email: result.user.email,
      profilePicture: result.user.photoURL,
      createdAt: new Date().toISOString()
    };
    const profileData = user;
    
    await fetch('https://api.meetz.site/profiles', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(profileData),
    });
    
    return user;
  } catch (error) {
    console.error("Error creating user with Google", error);
    throw error;
  }
};


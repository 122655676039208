// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCqY71gRwSY4dDI9ZNm_9KOrsiW-gc9OoA",
  authDomain: "meetz-8c652.firebaseapp.com",
  projectId: "meetz-8c652",
  storageBucket: "meetz-8c652.appspot.com",
  messagingSenderId: "965523585897",
  appId: "1:965523585897:web:1ccdabb3aa732c8fc49bf8",
  measurementId: "G-K24S07F1Z6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
export { app, auth, analytics };